import { createContext, useContext } from 'react';

type AbTestVariantIdContextValue = {
	variantId?: string | null;
};

export const AbTestVariantIdContext =
	createContext<AbTestVariantIdContextValue>({
		variantId: undefined,
	});

export const useAbTestVariantIdContext = (): AbTestVariantIdContextValue =>
	useContext(AbTestVariantIdContext);
